
import { BButton, BCard, BFormSelect, BFormGroup } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import ProductsTable from '@/components/menu/ProductsTable.vue'
import EditProductModal from '@/components/modals/EditProduct.vue'
import { IProduct } from '@/interfaces/IProduct'
import { ProductGroup } from '@/enums/productGroup'

@Component({
  components: {
    BButton,
    BCard,
    ProductsTable,
    EditProductModal,
    BFormSelect,
    BFormGroup,
  },
})
export default class Menu extends Vue {
  selectedGroup: ProductGroup | null = null
  groups: { value: ProductGroup | null; text: string }[] = [
    { value: null, text: '-' },
    { value: ProductGroup.hotDog, text: 'Хот-доги' },
    { value: ProductGroup.burger, text: 'Бургеры' },
    { value: ProductGroup.snack, text: 'Закуски' },
    { value: ProductGroup.drink, text: 'Напитки' },
    { value: ProductGroup.sauce, text: 'Соусы' },
    { value: ProductGroup.topping, text: 'Топпинги' },
  ]
  searchString = ''

  $refs!: {
    editProductModal: HTMLElement & { show: (product?: IProduct) => void }
  }

  addProductModalOpen() {
    this.$refs.editProductModal.show()
  }

  editProduct(product: IProduct) {
    this.$refs.editProductModal.show(product)
  }
}
