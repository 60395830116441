
import { BvEvent } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { productsStore } from '@/store/productsStore'
import { IProduct } from '@/interfaces/IProduct'
import { ProductGroup } from '@/enums/productGroup'

@Component
export default class EditProductModal extends Vue {
  state: 'create' | 'edit' = 'create'

  id: string | null = null
  title = ''
  details = ''
  weight = 0
  imageUrl: string | null = null

  group: ProductGroup | null = null
  groups: { value: ProductGroup; text: string }[] = [
    { value: ProductGroup.hotDog, text: 'Хот-доги' },
    { value: ProductGroup.burger, text: 'Бургеры' },
    { value: ProductGroup.snack, text: 'Закуски' },
    { value: ProductGroup.drink, text: 'Напитки' },
    { value: ProductGroup.sauce, text: 'Соусы' },
    { value: ProductGroup.topping, text: 'Топпинги' },
  ]

  imagePreview: string | ArrayBuffer | null = null
  image: File | null = null

  pending = false

  errors: { [key: string]: string | null } = {
    title: null,
    details: null,
    weight: null,
    image: null,
    group: null,
  }

  $refs!: {
    fileInput: HTMLInputElement
  }

  get modalTitle() {
    return this.state === 'create'
      ? 'Добавление позиции'
      : 'Редактирование позиции'
  }

  get okButtonText() {
    return this.state === 'create' ? 'Создать' : 'Сохранить'
  }

  get displayedImage() {
    if (this.imagePreview) {
      return this.imagePreview
    }

    if (this.imageUrl) {
      return `${process.env.VUE_APP_IMAGES_BASE_URL}/small/${this.imageUrl}`
      //return require(`../../../../dogshaus-frontend/static/images/products/small/${this.imageUrl}`)
    }

    return ''
  }

  show(product?: IProduct) {
    this.state = product ? 'edit' : 'create'

    if (this.state === 'edit') {
      this.fillData(product!)
    }

    this.$bvModal.show('edit-product-modal')
  }

  fillData(product: IProduct) {
    this.id = product.id
    this.group = product.group
    this.title = product.title
    this.details = product.details || ''
    this.weight = product.weight
    this.imageUrl = product.image
  }

  handleFileUpload() {
    let uploadedFiles = this.$refs.fileInput.files

    if (!uploadedFiles?.length) {
      return
    }

    this.image = uploadedFiles[0]
    this.setImagePreview(this.image)
  }

  setImagePreview(file: File) {
    if (/\.(jpe?g|png|gif|svg)$/i.test(file.name)) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.imagePreview = reader.result
        },
        false,
      )
      reader.readAsDataURL(file)
    }
  }

  async handleOk(e: BvEvent) {
    e.preventDefault()

    if (!this.validate()) {
      return
    }

    this.pending = true
    let result = false
    switch (this.state) {
      case 'create':
        result = await productsStore.addProduct(this.getProductData())
        break

      case 'edit':
        result = await productsStore.updateProduct(this.getProductData())
        break
    }
    this.pending = false

    if (result) {
      return this.$bvModal.hide('edit-product-modal')
    }
  }

  getProductData() {
    const formData = new FormData()

    if (this.image) {
      formData.append('image', this.image as Blob)
    }

    const data = {
      id: this.id,
      title: this.title,
      group: this.group,
      weight: this.weight,
      details: this.details,
      image: this.imageUrl,
    }
    formData.append('data', JSON.stringify(data))
    return formData
  }

  resetErrors() {
    for (const key in this.errors) {
      this.errors[key] = null
    }
  }

  validate() {
    this.resetErrors()

    if (!this.title) {
      this.errors.title = 'Введите название'
    }

    if (!this.weight) {
      this.errors.weight = 'Введите вес'
    }

    if (this.weight < 0) {
      this.errors.weight = 'Вес должен быть больше 0'
    }

    // if (!this.imagePreview && !this.imageUrl) {
    //   this.errors.image = 'Выберите изображение'
    // }

    if (!this.group) {
      this.errors.group = 'Выберите группу'
    }

    for (const key in this.errors) {
      const error = this.errors[key]
      if (error) {
        return false
      }
    }
    return true
  }

  reset() {
    this.resetErrors()
    this.id = null
    this.imageUrl = null
    this.imagePreview = null
    this.title = ''
    this.details = ''
    this.weight = 0
    this.image = null
    this.group = null
    this.pending = false
  }
}
