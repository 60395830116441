import Vue from 'vue'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import axios from '@/utils/axios'
import { showError, showSuccess } from '@/helpers/notifications'
import { AxiosResponse } from 'axios'
import { IProduct } from '@/interfaces/IProduct'

@Module({
  name: 'productsStore',
  namespaced: true,
  dynamic: true,
  store,
})
export default class ProductsStore extends VuexModule {
  private _products: IProduct[] = []

  get products() {
    return this._products
  }

  @Mutation
  private setProducts(products: IProduct[]) {
    this._products = products
  }

  @Mutation
  private addProductLocal(product: IProduct) {
    this._products.push(product)
  }

  @Mutation
  private updateProductLocal(product: IProduct) {
    const index = this._products.findIndex(p => p.id === product.id)
    Vue.set(this._products, index, product)
  }

  @Mutation
  private deleteProductLocal(id: string) {
    this._products = this._products.filter(p => p.id !== id)
  }

  @Action
  async fetchProducts() {
    try {
      const response: AxiosResponse<IProduct[]> = await axios.get('products/all')
      this.setProducts(response.data)
    } catch (error) {
      showError('При получении данных произошла ошибка')
    }
  }

  @Action
  async addProduct(data: FormData) {
    try {
      const response: AxiosResponse<IProduct> = await axios.post('products', data)
      this.addProductLocal(response.data)

      showSuccess('Позиция добавлена')
      return true
    } catch (error) {
      console.error(error)
      showError('При создании произошла ошибка')
      return false
    }
  }

  @Action
  async updateProduct(data: FormData) {
    try {
      const response: AxiosResponse<IProduct> = await axios.patch('products', data)
      this.updateProductLocal(response.data)
      showSuccess('Позиция обновлена')
      return true
    } catch (error) {
      console.error(error)
      showError('При сохранении произошла ошибка')
      return false
    }
  }

  @Action
  async deleteProduct(id: string) {
    try {
      await axios.delete(`products/${id}`)
      this.deleteProductLocal(id)
      showSuccess('Позиция удалена')
    } catch (error) {
      console.error(error)
      showError('При удалении произошла ошибка')
    }
  }
}

export const productsStore = getModule(ProductsStore)
