
import { productsStore } from '@/store/productsStore'
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator'
import { ProductGroup } from '@/enums/productGroup'
import { IProduct } from '@/interfaces/IProduct'
import DeleteConfirmationModal from '@/components/modals/DeleteConfirmation.vue'

@Component({ components: { DeleteConfirmationModal } })
export default class Menu extends Vue {
  @Ref() readonly deleteConfirmationModal!: {
    show: (title: string) => Promise<boolean>
  }

  @Prop(String)
  readonly filter: ProductGroup | null = null
  @Prop({ type: String, required: true })
  readonly search!: string

  pending = false
  fields = [
    {
      key: 'image',
      label: 'Изображение',
      sortable: false,
    },
    {
      key: 'title',
      label: 'Название',
      sortable: true,
      tdClass: 'title',
    },
    {
      key: 'group',
      label: 'Группа',
      sortable: true,
    },
    {
      key: 'buttons',
      label: '',
      sortable: false,
    },
  ]

  get products() {
    return productsStore.products.filter(product => {
      let finded = product.title
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (this.filter) {
        finded &&= product.group === this.filter
      }
      return finded
    })
  }

  @Emit()
  editProduct() {}

  async mounted() {
    await productsStore.fetchProducts()
  }

  getImage(value: string) {
    try {
      return `${process.env.VUE_APP_IMAGES_BASE_URL}/small/${value}`
      // return require(`../../../../dogshaus-frontend/static/images/products/small/${value}`)
    } catch (error) {
      return ''
    }
  }

  getFormattedGroup(group: ProductGroup) {
    switch (group) {
      case ProductGroup.hotDog:
        return 'Хот-доги'
      case ProductGroup.burger:
        return 'Бургеры'
      case ProductGroup.snack:
        return 'Закуски'
      case ProductGroup.drink:
        return 'Напитки'
      case ProductGroup.dessert:
        return 'Десерты'
      case ProductGroup.sauce:
        return 'Соусы'
      case ProductGroup.topping:
        return 'Топпинги'
    }
  }

  async deleteProduct(product: IProduct) {
    const result = await this.deleteConfirmationModal.show(product.title)
    if (result) {
      productsStore.deleteProduct(product.id)
    }
  }
}
